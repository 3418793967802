import {
  Box,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react"
import {
  BlockEntity,
  BlockEntityHydrated,
  EntityId,
  PageEntity,
} from "@jackfruit/common"
import { TFunction } from "i18next"
import { chunk } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { useBlocks } from "~/hooks/useBlocks"
import { useCart } from "~/hooks/useCart"
import { useImageRegions } from "~/hooks/useImageRegions"
import { useImages } from "~/hooks/useImages"
import { useLineItems } from "~/hooks/useLineItems"
import { usePage } from "~/hooks/usePage"
import { useProductPages } from "~/hooks/useProductPages"
import { useUploads } from "~/hooks/useUploads"
import Wrapper from "../Wrapper"

const MAX_IMAGE_PREVIEW_DESKTOP = 4
const MAX_IMAGE_PREVIEW_MOBILE = 3

export interface Props {}

function findHeaderBlock(block: BlockEntity): block is BlockEntityHydrated {
  return block.type === "header"
}

function getFooterText(page: PageEntity, t: TFunction) {
  if (page.isDeliveryEnabled && page.isPickupEnabled) {
    return t(
      "components.blocks.PhotosUploadedBlock.FooterText.DeliveryAndPickup"
    )
  }
  if (page.isDeliveryEnabled) {
    return t("components.blocks.PhotosUploadedBlock.FooterText.Delivery")
  }
  if (page.isPickupEnabled) {
    return t("components.blocks.PhotosUploadedBlock.FooterText.Pickup")
  }
  return null
}

const PhotosUploadedBlock: React.FC<Props> = () => {
  const { t } = useTranslation()
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })
  const { page } = usePage()
  const { cart } = useCart()
  const { blocks } = useBlocks()

  const { lineItems } = useLineItems(cart.lineItemIds)
  const productPageIds: EntityId[] = lineItems
    .map(lineItem => lineItem.productPageIds)
    .flat()

  const { productPages } = useProductPages(productPageIds)
  const imageRegionIds = productPages
    .map(productPage => productPage?.imageRegionIds)
    .flat()

  const { imageRegions } = useImageRegions(imageRegionIds)
  const uploadIds = imageRegions
    .map(imageRegion => imageRegion?.uploadId ?? [])
    .flat()

  const { uploads } = useUploads(uploadIds)
  const { images } = useImages(uploads.map(({ imageId }) => imageId))

  const imageCount = cart.lineItemIds.length
  const headerBlock = blocks.find(findHeaderBlock)

  const { mainNavigationBackgroundColor, mobileNavigationBackgroundColor } =
    headerBlock!.header

  const footerText = getFooterText(page, t)

  const maxImagePreview = isMobile
    ? MAX_IMAGE_PREVIEW_MOBILE
    : MAX_IMAGE_PREVIEW_DESKTOP
  const someImages = chunk(images, maxImagePreview)[0]
  const remaining = Math.max(imageCount - maxImagePreview, 0)

  return (
    <Wrapper
      py={8}
      px={4}
      bg={
        isMobile
          ? mobileNavigationBackgroundColor
          : mainNavigationBackgroundColor
      }
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection={{ base: "column", md: "column" }}
      >
        <Text fontWeight="bold" mb={2}>
          {t("components.blocks.PhotosUploadedBlock.Title", {
            count: imageCount,
          })}
        </Text>
        <HStack spacing={2}>
          {someImages.map((image, index) => {
            const isLast = index === someImages.length - 1
            const shouldShowMore = isLast && remaining > 0

            return (
              <Box
                key={image.id}
                width={70}
                height={70}
                backgroundImage={image?.localUrl}
                backgroundSize="cover"
                backgroundPosition="center"
                position="relative"
              >
                {shouldShowMore && (
                  <VStack
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    spacing={0}
                    backgroundColor="blackAlpha.600"
                  >
                    <Text fontSize={15} fontWeight="bold" color="white">
                      + {remaining + 1}
                    </Text>
                    <Text fontSize={15} fontWeight="bold" color="white">
                      {t("components.blocks.PhotosUploadedBlock.Photos")}
                    </Text>
                  </VStack>
                )}
              </Box>
            )
          })}
        </HStack>
        {Boolean(footerText) && (
          <Text fontSize={15} mt={3}>
            {footerText}
          </Text>
        )}
      </Flex>
    </Wrapper>
  )
}

export default PhotosUploadedBlock
